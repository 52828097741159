export enum SiteFrenchDsoEnum {
  enedis = "enedis",
  geredis = "geredis",
}

export enum SiteFinlandDsoEnum {
  elenia = "elenia",
  caruna = "caruna",
  savon_voima = "savon_voima",
  jse = "jse",
  kymenlaakson = "kymenlaakson",
  pks = "pks",
}

export enum SiteContryEnum {
  Finland = "Finland",
  France = "France",
}

export enum SiteOperatorEnum {
  NW_JMET = "NW JMET",
  NW_JMET2 = "NW JMET2",
  NW_JMET3 = "NW JMET3",
  NWJ_FIN_Oy = "NWJ FIN Oy",
}

export enum SiteFranceSupplierEnum {
  NWRE = "NWRE",
  ELMY = "ELMY",
}

export enum SiteFinlandSupplierEnum {
  NWRE = "NWRE",
  Entelios = "Entelios",
}

export enum RteComputationVersionEnum {
  soc_reserve = 1,
  t_tenue = 2,
}
