import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import ExplicitValuesBoolenInput from "components/commons/inputs/ExplicitValuesBooleanInput";
import { baseResources } from "config_infos";
import { SITE_MODES } from "enums";
import { RteComputationVersionEnum, SiteContryEnum, SiteOperatorEnum } from "enums/globalEnums";
import {
  RteComputationVersionChoices,
  siteCountryChoices,
  siteFinlandDsoChoices,
  siteFinlandSupplierChoices,
  siteFranceSupplierChoices,
  siteFrenchDsoChoices,
  siteOperatorChoices,
} from "enums/reactAdminChoices";
import { getDataproviderCustomErrorMessage } from "errors/handlers";
import React, { useEffect, useMemo } from "react";
import {
  AutocompleteArrayInput,
  DateTimeInput,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  NumberInput,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";
import { siteValidations } from "./validations";

type SitesBaseFormProps = {
  isCreateForm?: boolean;
};

type InputContainerProps = {
  source: string;
  InputType?: React.ComponentType<any>;
  gridItemProps?: any;
  inputProps?: any;
};

const InputContainer = ({ source, InputType = TextInput, gridItemProps, inputProps }: InputContainerProps) => {
  return (
    <Grid item xs={4} {...gridItemProps}>
      <InputType fullWidth source={source} {...inputProps} />
    </Grid>
  );
};

export default function SitesBaseForm({ isCreateForm = true }: SitesBaseFormProps) {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const record = useRecordContext();

  const handleSubmit: SubmitHandler<FieldValues> = async (values: any) => {
    const { configuration, ...data } = values;
    try {
      if (isCreateForm) {
        const responseSiteCreation = await dataProvider.create(baseResources.sites.SITES, { data });
        await dataProvider.create(`${baseResources.sites.SITES}`, {
          data: configuration,
          meta: { urlParams: `${responseSiteCreation.data.id}/configurations/` },
        });
        notify("Site and configuration created successfully", { type: "success" });
      } else {
        await dataProvider.update(baseResources.sites.SITES, { id: data.id, data, previousData: record });
        notify("Site updated successfully", { type: "success" });
      }
      redirect("list", baseResources.sites.SITES);
    } catch (error: any) {
      notify(getDataproviderCustomErrorMessage(error), { type: "error" });
    }
  };

  return (
    <SimpleForm mode="onBlur" validate={siteValidations} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Basic Information" />
            <CardContent>
              <Grid container spacing={2}>
                {isCreateForm && <InputContainer InputType={NumberInput} source="id" />}
                <InputContainer source="name" />
                <InputContainer InputType={SelectInput} source="country" inputProps={{ choices: siteCountryChoices }} />

                <FormDataConsumer>
                  {(formDataConsumerProps: FormDataConsumerRenderParams) => {
                    return <CountryDependentInputs {...formDataConsumerProps} />;
                  }}
                </FormDataConsumer>
                <Grid item xs={4}>
                  <ExplicitValuesBoolenInput label="IECharge" falseLabel="No" trueLabel="Yes" source="iecharge" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Aggregation Details" />
            <CardContent>
              <Grid container spacing={2}>
                <InputContainer InputType={DateTimeInput} source="valorization_start_date" />
                <InputContainer
                  source="rte_code"
                  inputProps={{
                    label: "Tranche TM RTE",
                  }}
                />
                <InputContainer InputType={SelectInput} source="mode" inputProps={{ choices: SITE_MODES }} />
                <InputContainer
                  InputType={ReferenceArrayInput}
                  source="group_ids"
                  inputProps={{
                    reference: baseResources.sites.GROUPS,
                    child: <AutocompleteArrayInput optionText="name" />,
                  }}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Location Information" />
            <CardContent>
              <Grid container spacing={2}>
                <InputContainer source="city" />
                <InputContainer InputType={NumberInput} source="zipcode" />
                <InputContainer InputType={NumberInput} source="latitude" />
                <InputContainer InputType={NumberInput} source="longitude" />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Network Information" />
            <CardContent>
              <Grid container spacing={2}>
                <InputContainer source="public_ip" />
                <InputContainer source="network_ip" />
                <InputContainer source="aggregator_module_healthcheck_url" />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Capacity and Efficiency" />
            <CardContent>
              <Grid container spacing={2}>
                <InputContainer InputType={NumberInput} source="max_cycles" />
                <InputContainer InputType={NumberInput} source="rack_count" />
                <InputContainer InputType={NumberInput} source="min_power_mw" />
                <InputContainer InputType={NumberInput} source="max_power_mw" />
                <InputContainer InputType={NumberInput} source="efficiency" />
                <InputContainer InputType={NumberInput} source="max_soc_mwh" />
                <InputContainer InputType={NumberInput} source="soh" />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Advanced Settings"
              subheader="Warning, this will automatically update the aggregator module if IP adress is filled. Note that the soc_inf/sup and soc_res_inf/sup are only required for FCR certification pre 2024."
              subheaderTypographyProps={{ color: "error" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <InputContainer
                  InputType={SelectInput}
                  source="rte_fcr_computation_version"
                  inputProps={{
                    choices: RteComputationVersionChoices,
                    // choices: RTE_FCR_COMPUTATION_VERSION,
                    defaultValue: RteComputationVersionEnum.t_tenue,
                  }}
                />
                <InputContainer InputType={NumberInput} source="useful_energy_at_1mw" />
                <InputContainer InputType={NumberInput} source="soc_reserve_inf" />
                <InputContainer InputType={NumberInput} source="soc_reserve_sup" />
                <InputContainer InputType={NumberInput} source="soc_inf" />
                <InputContainer InputType={NumberInput} source="soc_sup" />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {isCreateForm && (
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Site Configuration" />
              <CardContent>
                <Grid container spacing={2}>
                  <InputContainer
                    InputType={NumberInput}
                    source="configuration.power_mw"
                    inputProps={{ defaultValue: 1 }}
                  />
                  <InputContainer
                    InputType={NumberInput}
                    source="configuration.efficiency_roundtrip"
                    inputProps={{
                      defaultValue: 0.85,
                    }}
                  />
                  <InputContainer
                    InputType={SelectInput}
                    source="configuration.rte_fcr_computation_version"
                    inputProps={{
                      choices: RteComputationVersionChoices,
                      defaultValue: RteComputationVersionEnum.t_tenue,
                    }}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </SimpleForm>
  );
}

const CountryDependentInputs = ({ formData }: FormDataConsumerRenderParams) => {
  const { setValue } = useFormContext();
  const { country } = formData;

  const isFinland = country === SiteContryEnum.Finland;
  const disabled = !country;
  const helperText = disabled ? "You must choose a country first" : undefined;

  // Choices for DSO, supplier, and operator based on the country
  const dsoChoices = isFinland ? siteFinlandDsoChoices : siteFrenchDsoChoices;
  const supplierChoices = isFinland ? siteFinlandSupplierChoices : siteFranceSupplierChoices;

  const operatorChoices = useMemo(() => {
    return siteOperatorChoices.filter(({ id }) =>
      isFinland ? id === SiteOperatorEnum.NWJ_FIN_Oy : id !== SiteOperatorEnum.NWJ_FIN_Oy,
    );
  }, [isFinland]);

  // Reset fields when country changes
  useEffect(() => {
    setValue("dso", "");
    setValue("site_operator", "");
    setValue("supplier", "");
  }, [country, setValue]);

  const defaultInputProps = {
    disabled,
    helperText,
  };

  return (
    <>
      <InputContainer InputType={SelectInput} source="dso" inputProps={{ choices: dsoChoices, ...defaultInputProps }} />
      <InputContainer
        InputType={SelectInput}
        source="site_operator"
        inputProps={{ choices: operatorChoices, label: "SPV", ...defaultInputProps }}
      />
      <InputContainer
        InputType={SelectInput}
        source="supplier"
        inputProps={{ choices: supplierChoices, ...defaultInputProps }}
      />
      <InputContainer
        source="prm"
        inputProps={{ label: isFinland ? "Consumption point number (GSRN)" : "PRM", ...defaultInputProps }}
      />
    </>
  );
};
