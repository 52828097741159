import { enumToChoices } from "helpers/reactAdminHelpers";
import {
  SiteContryEnum,
  SiteFinlandDsoEnum,
  SiteFrenchDsoEnum,
  SiteOperatorEnum,
  SiteFranceSupplierEnum,
  SiteFinlandSupplierEnum,
  RteComputationVersionEnum,
} from "./globalEnums";

export type RaChoiceType = {
  id: string;
  name: string;
};

export const siteFrenchDsoChoices = enumToChoices(SiteFrenchDsoEnum);
export const siteFinlandDsoChoices = enumToChoices(SiteFinlandDsoEnum);

export const siteCountryChoices = enumToChoices(SiteContryEnum);

export const siteOperatorChoices = enumToChoices(SiteOperatorEnum);

export const siteFranceSupplierChoices = enumToChoices(SiteFranceSupplierEnum);
export const siteFinlandSupplierChoices = enumToChoices(SiteFinlandSupplierEnum);

export const RteComputationVersionChoices = enumToChoices(RteComputationVersionEnum);
